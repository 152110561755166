<template>
  <b-modal
    id="order-details"
    :title="`Order Details: ${selectedOrder.id ? selectedOrder.id : ''}`"
    title-class="text-black d-flex justify-content-center align-items-center"
    hide-footer
    no-close-on-backdrop
    size="xl"
    @shown="handleModalOpen"
    @hidden="handleModalClose"
  >
    <b-card
      no-body
    >
      <div v-if="Object.keys(selectedOrder).length > 0">
        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <b-col
              cols="12"
              xl="8"
              lg="12"
              md="12"
              sm="12"
              class="card-border-right"
            >
              <b-col
                cols="12"
                xl="5"
                lg="12"
                md="12"
                sm="12"
                offset-xl="4"
              >
                <!-- ORDER ID, TYPE AND SERVICE -->
                <b-card
                  class="border"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <h1 class="font-weight-bolder text-black">
                      #{{ selectedOrder.invoice.id }}
                    </h1>
<!--                    <button-->
<!--                      v-if="!selectedOrder.laundry_note && selectedOrder.status.id !== 7 && selectedOrder.status.id !== 8 && selectedOrder.user.deleted_at === ''"-->
<!--                      v-b-modal.order-note-modal-->
<!--                      class="btn btn-primary btn-sm"-->
<!--                    >-->
<!--                      &lt;!&ndash; selectedOrder.items.length > 0 &&  &ndash;&gt;-->
<!--                      Add Note-->
<!--                    </button>-->
                  </div>
                  <div class="d-flex justify-content-between align-items-center my-2">
<!--                    <div class="d-flex justify-content-center align-items-center">-->
<!--                      <img-->
<!--                        :src="require(`@/assets/images/icons/delivery-van.png`)"-->
<!--                        width="32"-->
<!--                      >-->
<!--                      <span class="mx-1">{{ selectedOrder.type }}</span>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-center align-items-center">-->
<!--                      <img src="@/assets/images/icons/laundry-basket.png">-->
<!--                      <span class="mx-1">{{ selectedOrder.pickup_type.name }}</span>-->
<!--                    </div>-->
                  </div>
                </b-card>
                <div
                  v-if="selectedOrder.initial_status === 'Pending'"
                  class="text-danger my-1"
                >
                  Waiting for the client to accept or reject your notes
                </div>
                <!-- ORDER STATUS -->
                <div>
                  <img src="@/assets/images/icons/process.png">
                  <span class="mx-1 font-weight-bolder text-uppercase">Order Status</span>
                </div>
                <div class="my-1">
                  <v-select
                    v-model="status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statuses"
                    :clearable="false"
                    label="name"
                    class="per-page-selector d-inline-block w-100 rounded"
                    placeholder="Change order status"
                    :disabled="!selectedOrder.state.editable ||
                      selectedOrder.initial_status === 'Pending'
                      ||selectedOrder.user.deleted_at !== ''"
                    @input="handleStatusChange"
                  />
<!--                  :class="{-->
<!--                  'select-success': !selectedOrder.status.color-->
<!--                  }"-->
                </div>
                <!-- (selectedOrder.delivery_address && !selectedOrder.delivery_driver) -->
<!--                <div-->
<!--                  v-if="(selectedOrder.pickup_type.id === 1 && !selectedOrder.pickup_driver)"-->
<!--                  class="my-1"-->
<!--                >-->
<!--                  <span class="text-danger">Please select pickup driver so you can change order status</span>-->
<!--                </div>-->
                <!-- PICKUP AND DELIVERY INFO -->
                <b-card
                  v-if="selectedOrder.pickup_time || selectedOrder.delivery_time"
                  class="border"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div
                      v-if="selectedOrder.pickup_time"
                      class="d-flex flex-column justify-content-center align-items-start"
                    >
                      <span class="my-1 font-weight-bolder text-uppercase">Pickup</span>
                      <span v-if="selectedOrder.pickup_time.day">{{ selectedOrder.pickup_time.day.week_day }}</span>
                      <span>{{ selectedOrder.pickup_time.start_at }} - {{ selectedOrder.pickup_time.end_at }}</span>
                      <!-- <span>Sun 12 Jun 2022</span>
                      <span>8:00 pm - 9:00 pm</span> -->
                    </div>
                    <div
                      v-if="selectedOrder.delivery_time"
                      class="d-flex flex-column justify-content-center align-items-start"
                    >
                      <span class="my-1 font-weight-bolder text-uppercase">Delivery</span>
                      <span v-if="selectedOrder.delivery_time.day">{{ selectedOrder.delivery_time.day.week_day }}</span>
                      <span>{{ selectedOrder.delivery_time.start_at }} - {{ selectedOrder.delivery_time.end_at }}</span>
                      <!-- <span>Mon 13 Jun 2022</span>
                      <span>9:00 pm - 10:00 pm</span> -->
                    </div>
                  </div>
                </b-card>
                <!-- COLLECTED BY -->
                <div v-if="selectedOrder.pickup_address">
                  <div class="my-1 d-flex justify-content-start align-items-center">
                    <img src="@/assets/images/icons/businessman.png">
                    <span class="mx-1 font-weight-bolder text-uppercase">Pickup Driver</span>
                    <!-- <span class="mx-1 text-black">{{ selectedOrder.pickup_driver ? selectedOrder.pickup_driver.full_name : '' }}</span> -->
                  </div>
                  <div
                    v-if="selectedOrder.pickup_driver"
                    class="my-1 text-success"
                  >
                    {{ selectedOrder.pickup_driver.full_name }}
                  </div>
                  <v-select
                    v-model="pickupDriver"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pickupDrivers"
                    :clearable="false"
                    label="full_name"
                    class="per-page-selector d-inline-block w-100"
                    placeholder="ASSIGN DRIVER"
                    :reduce="(option) => option.id"
                    :disabled="status.id >= 3 || selectedOrder.initial_status === 'Pending' || selectedOrder.user.deleted_at !== ''"
                    @input="handleAssignPickupDriver"
                  />
                  <!-- || status.id === 7 || status.id === 8 -->
                  <hr>
                </div>
                <!-- ASSIGN DRIVER -->
                <div
                  v-if="selectedOrder.delivery_address"
                  class="my-4"
                >
                  <div class="my-1 d-flex justify-content-start align-items-center">
                    <img src="@/assets/images/icons/add-user.png">
                    <span class="mx-1 font-weight-bolder text-uppercase">Delivery Driver</span>
                  </div>
                  <div
                    v-if="selectedOrder.delivery_driver"
                    class="my-1 text-success"
                  >
                    {{ selectedOrder.delivery_driver.full_name }}
                  </div>
                  <v-select
                    v-model="deliveryDriver"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="deliveryDrivers"
                    :clearable="false"
                    label="full_name"
                    class="per-page-selector d-inline-block w-100"
                    placeholder="ASSIGN DRIVER"
                    :reduce="(option) => option.id"
                    :disabled="status.id < 4 || status.id > 6 || selectedOrder.initial_status === 'Pending' || selectedOrder.user.deleted_at !== ''"
                    @input="handleAssignDeliveryDriver"
                  />
                  <!-- status.id === 7 || status.id === 8 => put this again and remove status.id < 4 || status.id > 6 if they want to enable select delivery driver in placed status. we removed it because it caused an error in firebase because the order is not exist there yet -->
                  <hr>
                </div>
                <!-- CREATED BY AND PLACED AT -->
                <b-card
                  class="border"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-column justify-content-center align-items-start">
                      <span class="my-1 font-weight-bolder">Created By</span>
                      <span v-if="selectedOrder.created_by">{{ selectedOrder.created_by.full_name }}</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-start">
                      <span class="my-1 font-weight-bolder">Placed At</span>
                      <span>{{ selectedOrder.created_at }}</span>
                    </div>
                  </div>
                  <div v-if="selectedOrder.items_add_date">
                    <hr />
                    <div class="d-flex flex-column justify-content-center align-items-start">
                      <span class="my-1 font-weight-bolder">Invoice Date</span>
                      <span>{{ selectedOrder.items_add_date }}</span>
                    </div>
                  </div>
                </b-card>
                <!-- LAUNDRY IMAGES -->
<!--                <b-card-->
<!--                  v-if="selectedOrder.laundry_images.length > 0 || selectedOrder.laundry_note"-->
<!--                  class="border my-4"-->
<!--                >-->
<!--                  <div-->
<!--                    class="d-flex justify-content-between align-items-center"-->
<!--                  >-->
<!--                    <div class="d-flex flex-column justify-content-center align-items-start">-->
<!--                      <div-->
<!--                        v-if="selectedOrder.laundry_note"-->
<!--                      >-->
<!--                        <div class="my-1 font-weight-bolder">-->
<!--                          Laundry Note-->
<!--                        </div>-->
<!--                        <div>-->
<!--                          {{ selectedOrder.laundry_note }}-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div-->
<!--                        v-if="selectedOrder.laundry_images.length > 0"-->
<!--                        class="my-1"-->
<!--                      >-->
<!--                        <div class="my-1 font-weight-bolder">-->
<!--                          Laundry Images-->
<!--                        </div>-->
<!--                        <div-->
<!--                          class="user-images-box"-->
<!--                        >-->
<!--                          <img-->
<!--                            v-for="(image, index) in selectedOrder.laundry_images"-->
<!--                            :key="index"-->
<!--                            v-b-modal.order-image-modal-->
<!--                            :src="url + image.image"-->
<!--                            class="mx-25 my-25 order-image cursor-pointer"-->
<!--                            width="120"-->
<!--                            height="120"-->
<!--                            @click="handlePreviewImage(image.image)"-->
<!--                          >-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </b-card>-->
              </b-col>
            </b-col>
            <b-col
              cols="12"
              xl="4"
              lg="12"
              md="12"
              sm="12"
              class="d-flex flex-column justify-content-between"
            >
              <div
                v-if="selectedOrder.user.deleted_at !== ''"
                class="text-danger"
              >
                This user has been deleted so you can't edit the order
              </div>
              <!-- CUSTOMER INFO -->
              <b-card
                class="border mb-2 mt-1"
              >
                <div
                  v-b-modal.customer-details
                  class="d-flex justify-content-between align-items-center cursor-pointer"
                >
                  <div class="d-flex flex-column justify-content-center align-items-start">
                    <span class="my-1 font-weight-bolder">User</span>
                    <span>{{ selectedOrder.user.full_name }}</span>
                    <span>{{ selectedOrder.user.phone }}</span>
                    <div v-if="selectedOrder.pickup_address">
                      <br>
                      <span><b>Pickup Address:</b></span>
                      <div class="mx-1">
                        <full-address :address="selectedOrder.pickup_address" />
                      </div>
                      <hr>
                    </div>
                    <div v-if="selectedOrder.delivery_address">
                      <br>
                      <span><b>Delivery Address:</b></span>
                      <div class="mx-1">
                        <full-address :address="selectedOrder.delivery_address" />
                      </div>
                      <!-- <hr> -->
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="text-primary font-weight-bold font-large-1"
                    />
                  </div>
                </div>
              </b-card>
              <!-- USER IMAGES -->
<!--              <b-card-->
<!--                v-if="selectedOrder.user_images.length > 0 || selectedOrder.user_note"-->
<!--                class="border my-2"-->
<!--              >-->
<!--                <div-->
<!--                  class="d-flex justify-content-between align-items-center"-->
<!--                >-->
<!--                  <div class="d-flex flex-column justify-content-center align-items-start">-->
<!--                    <div-->
<!--                      v-if="selectedOrder.user_note"-->
<!--                    >-->
<!--                      <div class="my-1 font-weight-bolder">-->
<!--                        User Note-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        {{ selectedOrder.user_note }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div-->
<!--                      v-if="selectedOrder.user_images.length > 0"-->
<!--                      class="my-1"-->
<!--                    >-->
<!--                      <div class="my-1 font-weight-bolder">-->
<!--                        User Images-->
<!--                      </div>-->
<!--                      <div-->
<!--                        class="user-images-box"-->
<!--                      >-->
<!--                        <img-->
<!--                          v-for="(image, index) in selectedOrder.user_images"-->
<!--                          :key="index"-->
<!--                          v-b-modal.order-image-modal-->
<!--                          :src="url + image.image"-->
<!--                          class="mx-25 my-25 order-image cursor-pointer"-->
<!--                          width="120"-->
<!--                          height="120"-->
<!--                          @click="handlePreviewImage(image.image)"-->
<!--                        >-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-card>-->
              <!-- ITEMS -->
              <app-collapse
                id="order-info"
                type="margin"
                class="mt-2"
              >
                <!-- ITEMS -->
                <app-collapse-item
                  title="Items"
                >
                  <template #img>
                    <img src="@/assets/images/icons/shopping-basket.png">
                  </template>
                  <div
                    v-for="(service, index) in groupedItems"
                    :key="index"
                  >
                    <div class="my-2">
                      <span
                        class="bg-primary bg-lighten-5 p-50 rounded"
                        :class="skin === 'dark' ? 'text-black' : ''"
                      >
                        {{ service[0] }}
                      </span>
                    </div>
                    <b-row
                      v-for="(item, ind) in service[1]"
                      :key="ind"
                      class="d-flex align-items-center"
                    >
                      <b-col
                        cols="12"
                        xl="7"
                        lg="12"
                        class="my-1"
                      >
                        <span :class="skin === 'dark' ? 'text-white' : 'text-black'">{{ item.name }} <span class="small text-dark">({{ item.parent_name }})</span></span>
                      </b-col>
                      <b-col
                        cols="12"
                        xl="2"
                        lg="12"
                      >
                        <span
                          class="font-weight-bold"
                          :class="skin === 'dark' ? 'text-white' : 'text-black'"
                        >
                          x{{ item.quantity }}
                        </span>
                      </b-col>
                      <b-col
                        cols="12"
                        xl="3"
                        lg="12"
                      >
                        <span
                          class="font-weight-bold"
                          :class="skin === 'dark' ? 'text-white' : 'text-black'"
                        >
                          {{ item.item_price }} KWD
                        </span>
                      </b-col>
                      <b-col
                        cols="12"
                        xl="12"
                        lg="12"
                      >
                        <span
                          v-if="item.note"
                          class="text-grey mx-2"
                        >
                          Note: {{ item.note }}
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- <div
                    v-if="selectedOrder.user_note"
                    class="my-2"
                  >
                    <span class="font-weight-bold text-grey">Note: {{ selectedOrder.user_note }}</span>
                  </div> -->
                </app-collapse-item>
                <!-- RECEIPT -->
                <app-collapse-item
                  title="Receipt"
                >
                  <template #img>
                    <img src="@/assets/images/icons/bill2.png">
                  </template>
                  <div
                    class="d-flex justify-content-between align-items-center my-1"
                    :class="skin === 'dark' ? 'text-white' : 'text-black'"
                  >
                    <span :class="skin === 'dark' ? 'text-white' : 'text-black'">Subtotal</span>
                    <span
                      class="font-weight-bold"
                      :class="skin === 'dark' ? 'text-white' : 'text-black'"
                    >
                      <span class="small text-dark">({{ totalItems }} {{ totalItems === 1 ? 'item': 'items' }})</span> {{ selectedOrder.total }} KWD
                    </span>
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-start text-black my-1">
                    <div
                      v-if="selectedOrder.promo_code"
                      class="d-flex justify-content-between align-items-center text-black my-1 w-100"
                    >
                      <span :class="skin === 'dark' ? 'text-white' : 'text-black'">Promo Code</span>
                      <span
                        class="font-weight-bold"
                        :class="skin === 'dark' ? 'text-white' : 'text-black'"
                      >
                        {{ selectedOrder.promo_code.amount }}
                      </span>
                    </div>
                    <div
                      v-if="selectedOrder.user_discount"
                      class="d-flex justify-content-between align-items-center text-black my-1 w-100"
                    >
                      <span :class="skin === 'dark' ? 'text-white' : 'text-black'">User Discount</span>
                      <span
                        class="font-weight-bold"
                        :class="skin === 'dark' ? 'text-white' : 'text-black'"
                      >
                        {{ selectedOrder.user_discount }} %
                      </span>
                    </div>
                    <div v-if="selectedOrder.promo_code && selectedOrder.user_discount">
                      <span class="text-success">Better Discount Applied</span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center text-black mt-2">
                    <span
                      class="font-weight-bold"
                      :class="skin === 'dark' ? 'text-white' : 'text-black'"
                    >
                      Total
                    </span>
                    <span class="text-primary font-medium-3 text-darken-2 font-weight-bolder">{{ +(selectedOrder.transaction.grand_total_price.toFixed(2)) }} KWD</span>
                  </div>
                </app-collapse-item>
                <!-- PAYMENTS -->
                <app-collapse-item
                  title="Payments"
                >
                  <template #img>
                    <img src="@/assets/images/icons/money1.png">
                  </template>
                  <div v-if="selectedOrder.payment_method || (!selectedOrder.payment_method && +selectedOrder.remaining_total === 0 && selectedOrder.paid)" class="d-flex justify-content-between align-items-center text-black my-1">
                    <span :class="skin === 'dark' ? 'text-white' : 'text-black'">Payment Method</span>
                    <span
                      v-if="selectedOrder.payment_method"
                      class="text-darken-2 font-weight-bolder"
                      :class="skin === 'dark' ? 'text-white' : 'text-black'"
                    >
                      {{ selectedOrder.payment_method.name }}
                    </span>
                    <span
                      v-else
                      class="text-darken-2 font-weight-bolder"
                      :class="skin === 'dark' ? 'text-white' : 'text-black'"
                    >
                      Subscription
                    </span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center text-black my-1">
                    <span :class="skin === 'dark' ? 'text-white' : 'text-black'">Amount Remaining</span>
                    <span class="text-success text-darken-2 font-weight-bolder">{{ +(selectedOrder.remaining_total.toFixed(2)) }} KWD</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center text-black mt-2">
                    <span :class="skin === 'dark' ? 'text-white' : 'text-black'">Amount Paid</span>
                    <span class="text-success text-darken-2 font-weight-bolder">{{ +(+selectedOrder.grand_total - +selectedOrder.remaining_total).toFixed(2) }} KWD</span>
                  </div>
                </app-collapse-item>
              </app-collapse>
              <div class="text-center mt-3">
                <!-- <b-dropdown
                  variant="outline-primary"
                  dropup
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreHorizontalIcon"
                    />
                    <span class="mx-1">More Actions</span>
                  </template>
                  <b-dropdown-item v-if="selectedOrder.status.id !== 4">
                    <router-link :to="`/orders/edit/${selectedOrder.id}`">
                      <feather-icon
                        icon="Edit2Icon"
                      />
                      <span class="mx-2">Edit Order</span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="ClipboardIcon"
                    />
                    <span class="mx-2">Print Receipt</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="paid">
                    <feather-icon
                      icon="TagIcon"
                    />
                    <span class="mx-2">Print Tags</span>
                  </b-dropdown-item>
                </b-dropdown> -->
                <b-row
                  v-if="selectedOrder.user.deleted_at === ''"
                >
                  <b-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                  >
                    <router-link
                      v-if="selectedOrder.items.length > 0"
                      :to="`/orders/print/${selectedOrder.id}`"
                      class="text-white btn btn-sm btn-secondary my-1 w-100"
                      target="_blank"
                    >
                      <!-- <feather-icon
                        icon="ClipboardIcon"
                      /> -->
                      <span class="mx-1">Print</span>
                    </router-link>
                  </b-col>
                  <b-col
                    v-if="(status.id === 4 || status.id === 5) && selectedOrder.initial_status === 'Approved'"
                    cols="12"
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                  >
                    <!-- && status.id !== 7 && status.id !== 8 -->

                    <router-link
                      :to="`/orders/edit/${selectedOrder.id}`"
                      class="text-white btn btn-sm btn-primary my-1 w-100"
                    >
                      <!-- <feather-icon
                        icon="Edit2Icon"
                      /> -->
                      <span class="mx-1">Edit</span>
                    </router-link>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                  >
<!--                    <a-->
<!--                      v-if=" !paid && status.id === 7 && selectedOrder.items.length > 0 && selectedOrder.payment_url"-->
<!--                      class="my-1 btn-sm btn btn-dark w-100"-->
<!--                      :href="`https://web.whatsapp.com/send?phone=+965${selectedOrder.user.phone}&text=${encodeURIComponent(selectedOrder.payment_url)}`"-->
<!--                      target="_blank"-->
<!--                    >-->
<!--                      <span class="align-middle">Send Payment Link</span>-->
<!--                    </a>-->
                    <b-button
                      v-if="!paid && status.id === 7 && selectedOrder.items.length > 0 && selectedOrder.remaining_total > 0"
                      variant="dark"
                      class="my-1 btn-sm w-100"
                      :disabled="payOnlineLoading"
                      @click="handleOnlinePay"
                    >
                      <span class="align-middle">{{ payOnlineLoading ? 'Loading...' : 'Send Payment Link' }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                  >
<!--                    <b-button-->
<!--                      v-if="selectedOrder.grand_total === selectedOrder.remaining_total && status.id < 8 && status.id >= 4 && selectedOrder.items.length > 0"-->
<!--                      variant="success"-->
<!--                      class="my-1 btn-sm"-->
<!--                      @click="handleFirstPay"-->
<!--                    >-->
<!--                      <span class="align-middle">Pay Order</span>-->
<!--                    </b-button>-->
                    <b-button
                      v-if=" !paid && status.id === 7 && selectedOrder.items.length > 0"
                      variant="success"
                      class="my-1 btn-sm w-100"
                      @click="handlePayNow"
                    >
                      <span class="align-middle">Pay Remaining Cash</span>
                    </b-button>
                  </b-col>
                </b-row>

              </div>
            </b-col>
          </b-row>

        </div>
        <order-add-note
          :order-id="selectedOrder.id"
          :update-order-data="handleChangeOrder"
        />
      </div>
      <div
        v-else
        class="text-center p-4"
      >
        <b-spinner label="Spinning" />
      </div>
    </b-card>
    <customer-details
      v-if="Object.keys(selectedCustomer).length > 0"
      :customer-id="selectedCustomer.id"
    />
    <!-- <order-receipt
      v-if="printToggle && Object.keys(selectedOrder).length > 0"
      :order="selectedOrder"
    /> -->
    <b-modal
      id="order-image-modal"
      centered
      hide-footer
      hide-header
      size="lg"
      title="Image"
    >
      <div class="text-center">
        <img
          :src="selectedImage"
          class="img-thumbnail"
          height="500"
        >
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import {
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BButton,
  VBToggle,
  // BDropdown,
  // BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import RightSidebar from '../sidebar/RightSidebar.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CustomerDetails from '../customers/customerDetails/CustomerDetails.vue'
import FullAddress from '../helpers/FullAddress.vue'
import OrderAddNote from './OrderAddNote.vue'
// import OrderReceipt from './print/OrderReceipt.vue'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    // RightSidebar,
    vSelect,
    AppCollapseItem,
    AppCollapse,
    // BDropdown,
    // BDropdownItem,
    BSpinner,
    CustomerDetails,
    FullAddress,
    OrderAddNote,
    // OrderReceipt,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  props: {
    order: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const orderUrlId = context.root.$route.params.id
    const { skin } = useAppConfig()
    const url = ref(`${process.env.VUE_APP_BASE_URL}/`)
    const selectedImage = ref('')
    const toast = useToast()
    const selectedOrder = ref({})
    const status = ref('')
    const statuses = ref([])
    const pickupDriver = ref('')
    const deliveryDriver = ref('')
    const pickupDrivers = ref([])
    const deliveryDrivers = ref([])
    const items = ref([])
    const paid = ref('')
    const groupedItems = ref(new Map())
    const fullPickupAddress = ref('')
    const fullDeliveryAddress = ref('')
    const totalItems = ref(0)
    const payOnlineLoading = ref(false)

    const selectedCustomer = ref({})
    // const printToggle = ref(false)

    const getStatuses = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/order-states`).then(res => {
        statuses.value = res.data.data.order_states

        if (items.value.length === 0) {
          statuses.value = statuses.value.filter(st => st.id !== 5 && st.id !== 6 && st.id !== 7)
        }
      })
    }

    const getDrivers = () => {
      if (selectedOrder.value.pickup_address) {
        // BY ADDRESS
        // axios.get(`${process.env.VUE_APP_BASE_API_URL}/drivers-byaddress/${selectedOrder.value.pickup_address.id}`).then(res => {
        //   pickupDrivers.value = res.data.data.items
        // })

        axios.get(`${process.env.VUE_APP_BASE_API_URL}/drivers`).then(res => {
          pickupDrivers.value = res.data.data.items
        })
      }

      if (selectedOrder.value.delivery_address) {
        // BY ADDRESS
        // axios.get(`${process.env.VUE_APP_BASE_API_URL}/drivers-byaddress/${selectedOrder.value.delivery_address.id}`).then(res => {
        //   deliveryDrivers.value = res.data.data.items
        // })

        axios.get(`${process.env.VUE_APP_BASE_API_URL}/drivers`).then(res => {
          deliveryDrivers.value = res.data.data.items
        })
      }
    }

    const handleModalOpen = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/orders/${props.order}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        if (res.data.code === 200) {
          selectedOrder.value = res.data.data.order
          selectedCustomer.value = res.data.data.order.user
          status.value = res.data.data.order.status
          // pickupDriver.value = res.data.data.order.pickup_driver?.id
          // deliveryDriver.value = res.data.data.order.delivery_driver?.id
          items.value = res.data.data.order.items
          paid.value = res.data.data.order.paid

          res.data.data.order.items.forEach(item => {
            totalItems.value += item.quantity
          })

          const itemsServiceTypes = new Set(items.value.map(item => item.service_type))

          itemsServiceTypes.forEach(service => {
            groupedItems.value.set(service, items.value.filter(item => item.service_type === service))
          })

          getDrivers()
          getStatuses()
        }
      })
    }

    const handleModalClose = () => {
      selectedCustomer.value = {}
      selectedOrder.value = {}
      totalItems.value = 0
      status.value = ''
      pickupDriver.value = ''
      deliveryDriver.value = ''
      items.value = []
      groupedItems.value = new Map()
      fullPickupAddress.value = ''
      fullDeliveryAddress.value = ''
      selectedImage.value = ''
      // printToggle.value = false
      if (orderUrlId) {
        context.root.$router.push('/orders')
      }
    }

    const handleChangeOrder = newOrder => {
      selectedOrder.value = newOrder
    }

    const handlePreviewImage = image => {
      selectedImage.value = url.value + image
    }

    const handleStatusChange = () => {
      if (status.value.id !== selectedOrder.value.status.id) {
        const data = {
          status: status.value.id,
          by: 'pos',
        }
        axios.put(`${process.env.VUE_APP_BASE_API_URL}/user/orders/${selectedOrder.value.id}/change-status`, data, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }).then(res => {
          if (res.data.code === 202) {
            selectedOrder.value = res.data.data.order
            paid.value = res.data.data.order.paid
            toast({
              component: ToastificationContent,
              props: {
                title: 'Status Updated Successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
          }
        }).catch(e => {
          status.value = selectedOrder.value.status
          toast({
            component: ToastificationContent,
            props: {
              title: `${e.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
    }

    const handleAssignPickupDriver = () => {
      // if (pickupDriver.value !== selectedOrder.value.pickup_driver.id || !selectedOrder.value.pickup_driver) {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/assign/${selectedOrder.value.id}/${pickupDriver.value}/pickup`, {}, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        if (res.data.code === 200) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Pickup Driver Assigned Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          selectedOrder.value = res.data.data.order
        }
      }).catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: `${e.response.data.message}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      // }
    }

    const handleAssignDeliveryDriver = () => {
      // if (deliveryDriver.value !== selectedOrder.value.delivery_driver.id || !selectedOrder.value.delivery_driver) {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/assign/${selectedOrder.value.id}/${deliveryDriver.value}/delivery`, {}, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        if (res.data.code === 200) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Delivery Driver Assigned Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          selectedOrder.value = res.data.data.order
        }
      }).catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: `${e.response.data.message}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      // }
    }

    const handleOnlinePay = () => {
      Swal.fire({
        title: 'Do you want to send online payment link to the user?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          if (selectedOrder.value.payment_url) {
            window.open(`https://web.whatsapp.com/send?phone=+965${selectedOrder.value.user.phone}&text=${encodeURIComponent(selectedOrder.value.payment_url)}`, '_blank')
          } else {
            payOnlineLoading.value = true
            const data = {
              payment_method_id: 3,
              deduct_from_wallet: false,
            }
            axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/orders/${selectedOrder.value.id}/pay`, data, {
              headers: {
                Authorization: localStorage.getItem('token'),
              },
            }).then(res => {
              if (res.data.code === 202) {
                payOnlineLoading.value = false
                if (res.data.data.order.payment_url) {
                  window.open(`https://web.whatsapp.com/send?phone=+965${selectedOrder.value.user.phone}&text=${encodeURIComponent(res.data.data.order.payment_url)}`, '_blank')
                }
                selectedOrder.value = res.data.data.order.order
              }
            }).catch(e => {
              payOnlineLoading.value = false
              toast({
                component: ToastificationContent,
                props: {
                  title: `${e.response.data.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          }
        }
      })
    }

    const handlePayNow = () => {
      Swal.fire({
        title: 'Do you want to make this order paid?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          axios.put(`${process.env.VUE_APP_BASE_API_URL}/user/orders/${selectedOrder.value.id}/paid`, {}, {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }).then(res => {
            if (res.data.code === 202) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Order Paid Successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              })
              paid.value = 1
              selectedOrder.value = res.data.data.order
            }
          }).catch(e => {
            toast({
              component: ToastificationContent,
              props: {
                title: `${e.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }

    return {
      url,
      selectedImage,
      selectedOrder,
      status,
      statuses,
      pickupDriver,
      deliveryDriver,
      fullPickupAddress,
      fullDeliveryAddress,
      pickupDrivers,
      deliveryDrivers,
      selectedCustomer,
      // printToggle,
      groupedItems,
      totalItems,
      handleModalOpen,
      handleModalClose,
      handleStatusChange,
      handlePayNow,
      handleOnlinePay,
      handleAssignPickupDriver,
      handleAssignDeliveryDriver,
      handlePreviewImage,
      handleChangeOrder,
      paid,
      payOnlineLoading,
      skin,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
  .user-images-box {
    height: 170px;
    overflow: auto;
  }
</style>
