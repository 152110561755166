<template>
  <div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row
          class="d-flex justify-content-end my-2"
        >
          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <router-link to="/orders/create">
                <b-button
                  variant="primary"
                  class="btn-sm"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Create Order</span>
                </b-button>
              </router-link>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex flex-column align-items-start justify-content-start">
              <!-- <label>Status</label> -->
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                label="name"
                :reduce="status => status.id"
                :clearable="true"
                class="w-100 font-small-1"
                placeholder="Select Status"
              />
            </div>
            <!-- <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div> -->
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex flex-column align-items-start justify-content-start">
              <!-- <label>Status</label> -->
              <v-select
                v-model="paymentStatusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentStatuses"
                label="key"
                :reduce="status => status.value"
                :clearable="true"
                class="w-100 font-small-1"
                placeholder="Select Payment Status"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <!-- <label>Is Current</label> -->
              <v-select
                v-model="isCurrentFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="currentList"
                :clearable="true"
                label="key"
                :reduce="curr => curr.value"
                class="w-100 font-small-1"
                placeholder="Is Current"
              />
            </div>
            <!-- <div class="d-flex align-items-center justify-content-start">
              <b-button
                v-b-toggle.orders-filter
                variant="outline-primary"
              >
                <feather-icon
                  icon="SlidersIcon"
                  class="mr-50"
                />
                <span class="align-middle">Filter</span>
              </b-button>
            </div> -->
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <v-select
                v-model="userFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users"
                :clearable="true"
                label="phone"
                :reduce="user => user.id"
                class="w-100 font-small-1"
                placeholder="User"
              />
            </div>
          </b-col>
<!--          <b-col-->
<!--            cols="12"-->
<!--            md="2"-->
<!--          >-->
<!--            <div class="w-100 d-flex flex-column align-items-start justify-content-start">-->
<!--              <v-select-->
<!--                v-model="pickupDriverFilter"-->
<!--                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                :options="drivers"-->
<!--                :clearable="true"-->
<!--                label="full_name"-->
<!--                :reduce="driver => driver.id"-->
<!--                class="w-100 font-small-1"-->
<!--                placeholder="Pickup Driver"-->
<!--              />-->
<!--            </div>-->
<!--          </b-col>-->
          <b-col
            cols="12"
            md="2"
          >
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <v-select
                v-model="deliveryDriverFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="drivers"
                :clearable="true"
                label="full_name"
                :reduce="driver => driver.id"
                class="w-100 font-small-1"
                placeholder="Delivery Driver"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refOrdersTable"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        :current-page="currentPage"
        :per-page="perPage"
      >

        <template #cell(id)="data">
          {{ data.item.id }}
        </template>

        <template #cell(invoice_id)="data">
          #{{ data.item.invoice.id }}
        </template>

        <template #cell(user)="data">
          <span v-if="data.item.user" class="text-nowrap">
            {{ data.item.user.full_name }}
          </span>
          <span
            v-if="data.item.user && data.item.user.deleted_at !== ''"
            class="text-danger small"
          >
            (Deleted)
          </span>
        </template>

<!--        <template #cell(type)="data">-->
<!--          &lt;!&ndash; <img-->
<!--            :src="require(`@/assets/images/icons/${data.item.type.icon}`)"-->
<!--            width="20"-->
<!--          > &ndash;&gt;-->
<!--          <span class="text-nowrap">-->
<!--            {{ data.item.type }}-->
<!--          </span>-->
<!--        </template>-->

        <template #cell(created_at)="data">
          <span>
            {{ data.item.created_at }}
          </span>
        </template>

<!--        <template #cell(initial_status)="data">-->
<!--          <span>-->
<!--            {{ data.item.initial_status }}-->
<!--          </span>-->
<!--        </template>-->

        <template #cell(status)="data">
          <b-badge
            v-if="data.item.state"
            pill
          >
            <!-- :variant="`light-${data.item.status.color}`" -->
            <small>{{ data.item.state.name}}</small>
          </b-badge>
        </template>

        <template #cell(payment)="data">
          <div class="d-flex align-items-center">
            <span :class="`bullet bullet-sm bullet-${data.item.paid ? 'success' : 'danger'} mx-25`" />
            <span class="text-nowrap">
              {{ data.item.paid ? 'Yes' : 'No' }}
            </span>
          </div>
        </template>

        <template #cell(details)="data">
          <b-button
            variant="flat-primary"
            @click="data.toggleDetails"
          >
            <feather-icon
              :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </b-button>
        </template>

        <template #row-details="data">
          <b-card>
            <b-row class="mb-2">
              <!-- <b-col
                cols="2"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="mb-1 card-border-right"
              >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      src="@/assets/images/icons/hanger.png"
                      width="20"
                    >&nbsp;
                    <strong>Quantity</strong>
                  </div>
                  <p>{{ data.item.quantity }}</p>
                </div>
              </b-col> -->
              <b-col
                cols="2"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="mb-1 card-border-right"
              >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      src="@/assets/images/icons/user.png"
                      width="20"
                    >&nbsp;
                    <strong>Created By</strong>
                  </div>
                  <p v-if="data.item.created_by">
                    {{ data.item.created_by.full_name }}
                  </p>
                </div>
              </b-col>
<!--              <b-col-->
<!--                cols="2"-->
<!--                xl="2"-->
<!--                lg="2"-->
<!--                md="2"-->
<!--                sm="2"-->
<!--                class="mb-1 card-border-right"-->
<!--              >-->
<!--                <div class="d-flex flex-column justify-content-center align-items-center">-->
<!--                  <div>-->
<!--                    <img-->
<!--                      src="@/assets/images/icons/laundry-basket1.png"-->
<!--                      width="20"-->
<!--                    >&nbsp;-->
<!--                    <strong>Pickup Type</strong>-->
<!--                  </div>-->
<!--                  <p>{{ data.item.pickup_type.name }}</p>-->
<!--                </div>-->
<!--              </b-col>-->
              <b-col
                cols="2"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="mb-1 card-border-right"
              >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      src="@/assets/images/icons/invoice.png"
                      width="20"
                    >&nbsp;
                    <strong>Total</strong>
                  </div>
                  <p>{{ (data.item.invoice.grand_total_price).toFixed(2) }} KWD</p>
                </div>
              </b-col>
              <b-col
                cols="2"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="mb-1"
              >
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      src="@/assets/images/icons/money2.png"
                      width="20"
                    >&nbsp;
                    <strong>Remaining Total</strong>
                  </div>
<!--                  <p>{{ (data.item.remaining_total).toFixed(2) }} KWD</p>-->
                </div>
              </b-col>
              <b-col
                cols="2"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="mb-1"
              >
                <div>
                  <b-button
                    v-b-modal.order-details
                    variant="outline-primary"
                    @click="selectedOrder = data.item.id"
                  >
                    Order Details
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            sm="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            sm="6"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!-- <right-sidebar
      id="orders-filter"
      title="Filter Orders"
    >
      <orders-filter-sidebar
        :is-current-filter.sync="isCurrentFilter"
        :status-filter.sync="statusFilter"
      />
    </right-sidebar> -->
    <order-details
      v-if="selectedOrder"
      :order="selectedOrder"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BTable,
  VBToggle,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import vSelect from 'vue-select'
import useOrders from './useOrders'
// import RightSidebar from '../sidebar/RightSidebar.vue'
// import OrdersFilterSidebar from './OrdersFilterSidebar.vue'
import OrderDetails from './OrderDetails.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect,
    // RightSidebar,
    // OrdersFilterSidebar,
    OrderDetails,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup(props, context) {
    const orderId = context.root.$route.params.id
    const selectedOrder = ref(null)
    const statuses = ref([])
    const paymentStatuses = ref([
      {
        key: 'Paid',
        value: '1',
      },
      {
        key: 'Not paid',
        value: '0',
      },
    ])
    const users = ref([])
    const drivers = ref([])

    const currentList = ref([
      {
        key: 'Current',
        value: 1,
      },
      {
        key: 'Not Current',
        value: 0,
      },
    ])

    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      lastPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdersTable,
      isCurrentFilter,
      statusFilter,
      paymentStatusFilter,
      userFilter,
      pickupDriverFilter,
      deliveryDriverFilter,

      refetchData,
    } = useOrders()

    const getStatuses = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/order-states`).then(res => {
        statuses.value = res.data.data.order_states
      })
    }

    const getUsers = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        users.value = res.data.data.items
      })
    }

    const getDrivers = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/drivers`).then(res => {
        drivers.value = res.data.data.items
      })
    }

    onMounted(() => {
      if (orderId) {
        selectedOrder.value = +orderId
        setTimeout(() => {
          context.root.$emit('bv::show::modal', 'order-details')
        }, 50)
      }
      getStatuses()
      getUsers()
      getDrivers()
    })

    return {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      lastPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdersTable,
      isCurrentFilter,
      statusFilter,
      paymentStatusFilter,
      userFilter,
      pickupDriverFilter,
      deliveryDriverFilter,
      statuses,
      currentList,
      users,
      drivers,

      refetchData,
      selectedOrder,
      paymentStatuses,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
